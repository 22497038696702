import React from 'react'
import { locale } from '../../../src/common/i18n'

interface Props {
  styles: { [k: string]: string }
}

const AvisoLegalCa = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Avís legal</h1>
    <p>
      Aquesta pàgina Web és propietat de la RODI METRO, S.L. (d'ara endavant
      RODI) amb NIF nº B60864311 i domicili al carrer C/ Pere de Cabrera, 16, 5ª
      planta de 25001 Lleida I Inscrita en el Registre Mercantil de Lleida en el
      Full&nbsp;L-20614, llibre 1046, foli 133 en el Registre Mercantil de
      Lleida en representació del GRUP RODI compost per les següents societats:
    </p>

    <div className={styles.talleres}>
      <p>
        <strong>RODI CINCASOL, S.L., B25573023 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI URGELL, S.L., B25807280 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI ANOIA, S.L., B61881868 (MANRESA)</strong>
      </p>
      <p>
        <strong>
          RODI SERVEIS INTEGRALS D'AUTOMOCIO, S.L., B61540118 (MANRESA)
        </strong>
      </p>
      <p>
        <strong>RODI ARAN, S.L., B25441866 (VIELHA)</strong>
      </p>
      <p>
        <strong>RODI AREA, S.L., B43742980 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI FRARES, S.L., B25698762 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS ARTIGUES ESTEVE, S.L., B25230632 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI BERGUEDA, S.L., B25458852 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI METRO, S.L., B60864311 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS SOLSONES, S.L., B25066655 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI TERUEL, S.L., B44206654 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI A, S.L.U. (SOC. ANDORRANA), L710003J (ANDORRA)</strong>
      </p>
      <p>
        <strong>MECATERUEL, S.L.L. (FRANQUICIA), B44207843 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI MOIÀ, S.L. (FRANQUICIA), B64374531 (BARCELONA)</strong>
      </p>
      <p>
        <strong>RODABAIX, S.L. (FRANQUICIA), B63861231 (BARCELONA)</strong>
      </p>
      <p>
        <strong>RODI PERELLO, S.L. (FRANQUICIA), B43835222 (TARRAGONA)</strong>
      </p>
      <p>
        <strong>REGUERA Y FERRER, S.L. ( FRANQ.), B22387559 (HUESCA)</strong>
      </p>
      <p>
        <strong>
          NEUMATICOS ARMANDO PUEYO, S.L. (FRANQUICIA),&nbsp;KSC B43381862
          (TARRAGONA)
        </strong>
      </p>
      <p>
        <strong>
          EL PASO 2000 TECHNOLOGY, S.L.U., B76177880 (TELDE, LAS PALMAS)
        </strong>
      </p>
      <p>
        <strong>
          NEX TYRES, S.L. Sucursal en Portugal, 980539307 (PORTUGAL)
        </strong>
      </p>
      <p>
        <strong>NEX TYRES, S.L., B25231789 (LLEIDA)</strong>
      </p>
    </div>
    <p>
      Per a qualsevol consulta o proposta, contacti'ns en l'e-mail
      atencionalcliente@rodi.es o trucant al telèfon 900 922 922.
    </p>
    <p>
      Aquesta pàgina Web es regeix per la normativa exclusivament aplicable a
      Espanya, quedant sotmesa a ella, tant nacionals com a estrangers que
      utilitzin aquesta Web.
    </p>
    <p>
      L'accés a la nostra pàgina Web per part de l'USUARI és gratuït i està
      condicionat a la prèvia lectura i acceptació integra, expressa i sense
      reserves de les presents CONDICIONS GENERALS D'ÚS vigents al moment de
      l'accés, que preguem llegeixi detingudament. L'USUARI al moment que
      utilitza el nostre portal, els seus continguts o serveis, accepta i se
      sotmet expressament a les condicions generals d'ús del mateix. Si l'usuari
      no estigués d'acord amb les presents condicions d'ús, haurà d'abstenir-se
      d'utilitzar aquest portal i operar per mitjà del mateix.
    </p>
    <p>
      El subministrament de dades personals a través del nostre portal requereix
      una edat mínima de 14 anys, o si escau, disposar de capacitat jurídica
      suficient per contractar.
    </p>
    <p>
      A qualsevol moment podrem modificar la presentació i configuració de la
      nostra Web, ampliar o reduir serveis, i fins i tot suprimir-la de la
      Xarxa, així com els serveis i continguts prestats, tot això de forma
      unilateral i sense previ avís.
    </p>
    <h2 className={styles.section_title}>
      <strong>A. Propietat intel·lectual</strong>
    </h2>
    <p>
      Tots els continguts, textos, imatges, i codis font són propietat de RODI o
      de tercers que als quals s'han adquirit els seus drets d'explotació, i
      estan protegits pels drets de Propietat Intel·lectual i Industrial.
    </p>
    <p>
      L'usuari únicament té dret a un ús privat dels mateixos, sense ànim de
      lucre, i necessita autorització expressa per modificar-los, reproduir-los,
      explotar-los, distribuir-los o qualsevol dret pertanyent al seu titular.
    </p>
    <p>
      RODI és una marca registrada, i està prohibida la seva reproducció,
      imitació, utilització o inserció d'aquestes marques sense la nostra deguda
      autorització.
    </p>
    <p>
      L'establiment d'enllaços al nostre portal no confereix cap dret sobre el
      mateix. Així mateix, el simple fet d'establir un enllaç a la nostra pàgina
      web no dóna dret a atorgar-se la categoria de col·laborador o soci.
    </p>
    <p>
      RODI queda exonerat de responsabilitat per qualsevol reclamació respecte
      als drets de propietat intel·lectual dels articles i imatges publicades
      per tercers al seu portal.
    </p>
    <p>
      Està absolutament prohibida la imitació ja sigui total o parcial del
      nostre portal.
    </p>
    <h2 className={styles.section_title}>
      <strong>B. Condicions d'accés</strong>
    </h2>
    <p>
      L'accés a la nostra pàgina Web és gratuït i no exigeix prèvia subscripció
      o registre. No obstant això, determinats serveis són d’accés restringit a
      determinats usuaris registrats i requereixen la identificació mitjançant
      contrasenyes. Aquests serveis quedaran degudament identificats a la
      Web.&nbsp;
    </p>
    <p>
      L'enviament de dades personals implica l'acceptació expressa per part de
      l'USUARI de la nostra política de privacitat.
    </p>
    <p>
      L'usuari ha d'accedir a la nostra pàgina Web conforme a la bona fe, les
      normes d'ordre públic, a les presents Condicions Generals d'ús. L'accés al
      nostre lloc web es realitza sota la pròpia i exclusiva responsabilitat de
      l'usuari, que respondrà en tot cas dels danys i perjudicis que pugui
      causar a tercers o a nosaltres mateixos.
    </p>
    <p>
      L'usuari té expressament prohibit la utilització i obtenció dels serveis i
      continguts oferts a la present pàgina web, per procediments diferents als
      estipulats en les presents condicions d'ús.
    </p>
    <p>
      L'USUARI té prohibit qualsevol tipus d'acció sobre el nostre portal que
      origini una excessiva sobrecàrrega de funcionament als nostres sistemes
      informàtics, així com la introducció de virus, o instal·lació de robots, o
      programari que alteri el normal funcionament de la nostra web, o en
      definitiva pugui causar danys als nostres sistemes informàtics.
    </p>
    <p>
      Tenint en compte la impossibilitat de control respecte a la informació,
      continguts i serveis que continguin altres websites a les quals es pugui
      accedir a través dels enllaços que la nostra pàgina web pugui posar a la
      seva disposició, li comuniquem que RODI, queda eximida de qualsevol
      responsabilitat pels danys i perjudicis de tota classe que poguessin
      derivar per la utilització d'aquestes pàgines web alienes a la nostra
      empresa per part de l'usuari.&nbsp;
    </p>
    <p>
      RODI es reserva el dret unilateral i sense preavís de donar de baixa a
      qualsevol USUARI que l'organització entengui que ha vulnerat les
      condicions que regeixen l'ús de la nostra pàgina web, sense que l'USUARI
      tingui dret a reclamació de cap tipus per aquestes accions. Així mateix,
      es reserva el dret d'exercitar les accions legals oportunes contra aquells
      que vulnerin les presents condicions generals d'ús, acceptant l'USUARI que
      la no iniciació d'aquestes accions no constitueix una renúncia formal a
      les mateixes, romanent aquestes vigents fins als terminis legals de
      prescripció de les infraccions.
    </p>
    <h2 className={styles.section_title}>
      <strong>C. Política de privacitat</strong>
    </h2>
    <p>
      La confidencialitat i la seguretat són valors primordials de RODI i, en
      conseqüència, assumim el compromís de garantir la privadesa de l'Usuari a
      tot moment i de no recaptar informació innecessària.
    </p>
    <p>
      El subministrament de dades personals a través del nostre portal requereix
      una edat mínima de 14 anys i l'acceptació expressa de la nostra Política
      de Privacitat.
    </p>
    <h2 className={styles.section_title}>
      <strong>D. Responsabilitats</strong>
    </h2>
    <p>
      En posar a la disposició de l'usuari aquesta pàgina Web volem oferir-li un
      servei de qualitat, utilitzant la màxima diligència en la prestació del
      mateix així com en els mitjans tecnològics utilitzats. No obstant això, no
      respondrem de la presència de virus i altres elements que d'alguna manera
      puguin danyar el sistema informàtic de l'usuari.&nbsp;
    </p>
    <p>
      RODI no garanteix que la disponibilitat del servei sigui contínua i
      ininterrompuda, per circumstàncies originades per problemes a la xarxa
      d'Internet, avaries en els dispositius informàtics o altres circumstàncies
      imprevisibles, de manera que l'USUARI accepta suportar dins d'uns límits
      raonables aquestes circumstàncies, per la qual cosa renuncia expressament
      a reclamar a RODI qualsevol responsabilitat per les possibles fallades,
      errors i ús del servei.
    </p>
    <p>
      L'usuari assumeix tota la responsabilitat derivada de l'ús de la nostra
      pàgina web, sent l'únic responsable de tot efecte directe o indirecte que
      sobre la pàgina web es derivi, incloent, de forma enunciativa i no
      limitativa, tot resultat econòmic, tècnic i/o jurídic advers, així com la
      defraudació de les expectatives generades pel nostre portal, obligant-se
      l'usuari a mantenir indemne a RODI per qualssevol reclamacions derivades,
      directa o indirectament de tals fets.
    </p>
    <p>
      RODI no garanteix l'exactitud, veracitat i vigència dels continguts
      d'aquesta pàgina web, ja siguin propis, de tercers, o enllaçables a altres
      webs, quedant totalment exonerada de qualsevol responsabilitat derivada de
      l'ús d'aquests.
    </p>
    <p>
      RODI queda exonerat de qualsevol responsabilitat derivada de qualsevol
      reclamació, inclòs el pagament d'honoraris a advocats, per les demandes i
      reclamacions originades per tercers per l'incompliment per part de
      l'USUARI de les nostres condicions d'ús, accés i política de privadesa, o
      qualsevol una altra reclamació per l'incompliment de la legislació vigent.
    </p>
    <p>
      L'USUARI reconeix que ha entès tota la informació respecte a les
      condicions d'ús del nostre portal, i reconeix que són suficients per a
      l'exclusió de l'error en aquestes, i per tant, les accepta integra i
      expressament.
    </p>
    <p>
      L'USUARI és plenament conscient que la mera navegació per la present
      pàgina web, així com la utilització dels seus serveis, implica
      l'acceptació de les presents condicions d'ús.
    </p>
    <p>
      Tot el referent a la nostra pàgina web, es regeix exclusivament per les
      lleis espanyoles. En el cas que es produeixi qualsevol tipus de
      discrepància o diferència entre les parts en relació amb la interpretació
      i contingut de la present pàgina web, totes les parts se sotmeten, amb
      renúncia expressa a qualsevol altre fur, als Jutjats i Tribunals de
      Lleida.
    </p>
    <h2 className={styles.section_title}>
      <strong>E. Vigència de les condicions generals d'accés a la web</strong>
    </h2>
    <p>
      Les presents Condicions Generals d'Ús han estat modificades amb data
      25/05/2018. A qualsevol moment podem procedir a la seva modificació: per
      favor, comprovi la data d'emissió en cada ocasió en què es connecti a la
      nostra pàgina Web i així tindrà la certesa que no s'ha produït modificació
      alguna que li afecti.
    </p>
    <p>
      Per a qualsevol qüestió respecte a les Condicions d'Ús de la nostra pàgina
      web, pot posar-se en contacte amb nosaltres en les dades a dalt indicades,
      o amb Lant Advocats en info@lant-advocats.com o en{' '}
      <a
        target="_blank"
        rel="noopener norefferer"
        href="https://www.lant-abogados.com/">
        www.lant-abogados.com
      </a>
      .
    </p>
  </div>
)

const AvisoLegalEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Aviso legal</h1>
    <p>
      Esta página Web es propiedad de RODI METRO, S.L. (en adelante RODI), con
      NIF nº B60864311 y domicilio en C/ Pere de Cabrera, 16, 5ª planta de 25001
      Lleida e Inscrita en el Registro Mercantil de Lleida en el Tomo 1046, Hoja
      L-20614, Folio 133 en Registro Mercantil de Lleida en representación del
      GRUPO RODI compuesto por la siguientes sociedades:
    </p>

    <div className={styles.talleres}>
      <p>
        <strong>RODI CINCASOL, S.L., B25573023 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI URGELL, S.L., B25807280 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI ANOIA, S.L., B61881868 (MANRESA)</strong>
      </p>
      <p>
        <strong>
          RODI SERVEIS INTEGRALS D'AUTOMOCIO, S.L., B61540118 (MANRESA)
        </strong>
      </p>
      <p>
        <strong>RODI ARAN, S.L., B25441866 (VIELHA)</strong>
      </p>
      <p>
        <strong>RODI AREA, S.L., B43742980 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI FRARES, S.L., B25698762 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS ARTIGUES ESTEVE, S.L., B25230632 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI BERGUEDA, S.L., B25458852 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI METRO, S.L., B60864311 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS SOLSONES, S.L., B25066655 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI TERUEL, S.L., B44206654 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI A, S.L.U. (SOC. ANDORRANA), L710003J (ANDORRA)</strong>
      </p>
      <p>
        <strong>MECATERUEL, S.L.L. (FRANQUICIA), B44207843 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI MOIÀ, S.L. (FRANQUICIA), B64374531 (BARCELONA)</strong>
      </p>
      <p>
        <strong>RODABAIX, S.L. (FRANQUICIA), B63861231 (BARCELONA)</strong>
      </p>
      <p>
        <strong>RODI PERELLO, S.L. (FRANQUICIA), B43835222 (TARRAGONA)</strong>
      </p>
      <p>
        <strong>REGUERA Y FERRER, S.L. ( FRANQ.), B22387559 (HUESCA)</strong>
      </p>
      <p>
        <strong>
          NEUMATICOS ARMANDO PUEYO, S.L. (FRANQUICIA),&nbsp;KSC B43381862
          (TARRAGONA)
        </strong>
      </p>
      <p>
        <strong>
          EL PASO 2000 TECHNOLOGY, S.L.U., B76177880 (TELDE, LAS PALMAS)
        </strong>
      </p>
      <p>
        <strong>
          NEX TYRES, S.L. Sucursal en Portugal, 980539307 (PORTUGAL)
        </strong>
      </p>
      <p>
        <strong>NEX TYRES, S.L., B25231789 (LLEIDA)</strong>
      </p>
    </div>

    <p>
      Para cualquier consulta o propuesta, contáctenos en el e-mail
      atencionalcliente@rodi.es o llamando al teléfono 900 922 922.
    </p>

    <p>
      Esta página Web se rige por la normativa exclusivamente aplicable en
      España, quedando sometida a ella, tanto nacionales como extranjeros que
      utilicen esta Web.
    </p>
    <p>
      El acceso a nuestra página Web por parte del USUARIO es gratuito y está
      condicionado a la previa lectura y aceptación Íntegra, expresa y sin
      reservas de las presentes CONDICIONES GENERALES DE USO vigentes en el
      momento del acceso, que rogamos lea detenidamente. El USUARIO en el
      momento que utiliza nuestro portal, sus contenidos o servicios, acepta y
      se somete expresamente a las condiciones generales de uso del mismo. Si el
      usuario no estuviere de acuerdo con las presentes condiciones de uso,
      deberá abstenerse de utilizar este portal y operar por medio del mismo.
    </p>
    <p>
      El suministro de datos personales a través de nuestro portal requiere una
      edad mínima de 14 años, o en su caso, disponer de capacidad jurídica
      suficiente para contratar.
    </p>
    <p>
      En cualquier momento podremos modificar la presentación y configuración de
      nuestra Web, ampliar o reducir servicios, e incluso suprimirla de la Red,
      así como los servicios y contenidos prestados, todo ello de forma
      unilateral y sin previo aviso.
    </p>

    <h2 className={styles.section_title}>
      <strong>A. Propiedad intelectual</strong>
    </h2>
    <p>
      Todos los contenidos, textos, imágenes, y códigos fuente son propiedad de
      GRUPO RODI o de terceros a los que se han adquirido sus derechos de
      explotación, y están protegidos por los derechos de Propiedad Intelectual
      e Industrial.
    </p>
    <p>
      El usuario únicamente tiene derecho a un uso privado de los mismos, sin
      ánimo de lucro, y necesita autorización expresa para modificarlos,
      reproducirlos, explotarlos, distribuirlos o ejercer cualquier derecho
      perteneciente a su titular.
    </p>
    <p>
      GRUPO RODI es una marca registrada, y está prohibida su reproducción,
      imitación, utilización o inserción de estas marcas sin nuestra debida
      autorización.
    </p>
    <p>
      El establecimiento de enlaces a nuestro portal no confiere ningún derecho
      sobre el mismo. Asimismo, el simple hecho de establecer un enlace a
      nuestra página web no da derecho a otorgarse la categoría de colaborador o
      socio.
    </p>
    <p>
      GRUPO RODI queda exonerado de responsabilidad por cualquier reclamación
      respecto a los derechos de propiedad intelectual de los artículos e
      imágenes publicadas por terceros en su portal.
    </p>
    <p>
      Está absolutamente prohibida la imitación ya sea total o parcial de
      nuestro portal.
    </p>

    <h2 className={styles.section_title}>
      <strong>B. Condiciones de acceso y uso</strong>
    </h2>
    <p>
      El acceso a nuestra página Web es gratuito y no exige suscripción o
      registro previo. Sin embargo, determinados servicios son de acceso
      restringido a determinados usuarios registrados y requieren la
      identificación mediante contraseñas. Estos servicios quedarán debidamente
      identificados en la Web.
    </p>
    <p>
      El envío de datos personales implica la aceptación expresa por parte del
      USUARIO de nuestra política de privacidad.
    </p>
    <p>
      El usuario debe acceder a nuestra página Web conforme a la buena fe, las
      normas de orden público y a las presentes Condiciones Generales de uso. El
      acceso a nuestro sitio Web se realiza bajo la propia y exclusiva
      responsabilidad del usuario, que responderá en todo caso de los daños y
      perjuicios que pueda causar a terceros o a nosotros mismos.
    </p>
    <p>
      El usuario tiene expresamente prohibida la utilización y obtención de los
      servicios y contenidos ofrecidos en la presente página web, por
      procedimientos distintos a los estipulados en las presentes condiciones de
      uso.
    </p>
    <p>
      El USUARIO tiene prohibido cualquier tipo de acción sobre nuestro portal
      que origine una excesiva sobrecarga de funcionamiento a nuestros sistemas
      informáticos, así como la introducción de virus, o instalación de robots,
      o software que altere el normal funcionamiento de nuestra web, o en
      definitiva pueda causar daños a nuestros sistemas informáticos.
    </p>
    <p>
      Teniendo en cuenta la imposibilidad de control respecto a la información,
      contenidos y servicios que contengan otras páginas web a los que se pueda
      acceder a través de los enlaces que nuestra página web pueda poner a su
      disposición, le comunicamos que GRUPO RODI, queda eximida de cualquier
      responsabilidad por los daños y perjuicios de toda clase que pudiesen
      derivar por la utilización de esas páginas web, ajenas a nuestra empresa,
      por parte del usuario.
    </p>
    <p>
      GRUPO RODI se reserva el derecho unilateral y sin preaviso de dar de baja
      a cualquier USUARIO que la organización entienda que ha vulnerado las
      condiciones que rigen el uso de nuestra página web, sin que el USUARIO
      tenga derecho a reclamación de ningún tipo por este tipo de acciones.
      Asimismo, se reserva el derecho de ejercitar las acciones legales
      oportunas contra aquellos que vulneren las presentes condiciones generales
      de uso, aceptando el USUARIO que la no iniciación de estas acciones no
      constituye una renuncia formal a los mismas, permaneciendo éstas vigentes
      hasta los plazos legales de prescripción de las infracciones.
    </p>

    <h2 className={styles.section_title}>
      <strong>C. Política de privacidad</strong>
    </h2>
    <p>
      La confidencialidad y la seguridad son valores primordiales de GRUPO RODI
      y, en consecuencia, asumimos el compromiso de garantizar la privacidad del
      Usuario en todo momento y de no recabar información innecesaria.
    </p>
    <p>
      El suministro de datos personales a través de nuestro portal requiere una
      edad mínima de 14 años y la aceptación expresa de nuestra Política de
      Privacidad.
    </p>

    <h2 className={styles.section_title}>
      <strong>D. Responsabilidades</strong>
    </h2>
    <p>
      Al poner a disposición del usuario esta página Web queremos ofrecerle un
      servicio de calidad, utilizando la máxima diligencia en la prestación del
      mismo, así como en los medios tecnológicos utilizados. No obstante, no
      responderemos de la presencia de virus y otros elementos que de algún modo
      puedan dañar el sistema informático del usuario.
    </p>
    <p>
      GRUPO RODI no garantiza que la disponibilidad del servicio sea continua e
      ininterrumpida, por circunstancias originadas por problemas en la red de
      Internet, averías en los dispositivos informáticos u otras circunstancias
      imprevisibles, de manera que el USUARIO acepta soportar dentro de unos
      límites razonables estas circunstancias, por lo que renuncia expresamente
      a reclamar a GRUPO RODI cualquier responsabilidad por los posibles fallos,
      errores y uso del servicio.
    </p>
    <p>
      El USUARIO asume toda la responsabilidad derivada del uso de nuestra
      página web, siendo el único responsable de todo efecto directo o indirecto
      que sobre la página web se derive, incluyendo, de forma enunciativa y no
      limitativa, todo resultado económico, técnico y/o jurídico adverso, así
      como la defraudación de las expectativas generadas por nuestro portal,
      obligándose el usuario a mantener indemne a GRUPO RODI por cualesquiera
      reclamaciones derivadas, directa o indirectamente de tales hechos.
    </p>
    <p>
      GRUPO RODI no garantiza la exactitud, veracidad y vigencia de los
      contenidos de esta página web, ya sean propios, de terceros, o enlazables
      a otras webs, quedando totalmente exonerada de cualquier responsabilidad
      derivada del uso de la mismos.
    </p>
    <p>
      GRUPO RODI queda exonerada de cualquier responsabilidad derivada de
      cualquier reclamación, incluido el pago de honorarios a abogados, por las
      demandas y reclamaciones originadas por terceros por el incumplimiento por
      parte del USUARIO de nuestras condiciones de uso, acceso y política de
      privacidad, o cualquiera otra reclamación por el incumplimiento de la
      legislación vigente.
    </p>
    <p>
      El USUARIO reconoce que ha entendido toda la información respecto a las
      condiciones de uso de nuestro portal, y reconoce que son suficientes para
      la exclusión del error en las mismas, y por lo tanto, las acepta integra y
      expresamente.
    </p>
    <p>
      El USUARIO es plenamente consciente de que la mera navegación por la
      presente página web, así como la utilización de sus servicios, implica la
      aceptación de las presentes condiciones de uso.
    </p>
    <p>
      Todo lo referente a nuestra página web, se rige exclusivamente por las
      leyes españolas. En el caso de que se produzca cualquier tipo de
      discrepancia o diferencia entre las partes en relación con la
      interpretación y contenido de la presente página web, todas las partes se
      someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y
      Tribunales de Lleida.
    </p>

    <h2 className={styles.section_title}>
      <strong>
        E. Vigencia de las condiciones generales de acceso a la web
      </strong>
    </h2>
    <p>
      Las presentes Condiciones Generales de Uso han sido modificadas con fecha
      25/05/2018. En cualquier momento podemos proceder a su modificación: por
      favor, compruebe la fecha de emisión en cada ocasión en que se conecte a
      nuestra página Web y así tendrá la certeza de que no se ha producido
      modificación alguna que le afecte.
    </p>
    <p>
      Para cualquier cuestión respecto a las Condiciones de Uso de nuestra
      página web, puede ponerse en contacto con nosotros en los datos arriba
      indicados, o con Lant Abogados en info@lant-abogados.com o en{' '}
      <a
        target="_blank"
        rel="noopener norefferer"
        href="https://www.lant-abogados.com/">
        www.lant-abogados.com
      </a>
      .
    </p>
  </div>
)

const AvisoLegalContents = ({ ...props }: Props) =>
  locale === 'es' ? <AvisoLegalEs {...props} /> : <AvisoLegalCa {...props} />

export default AvisoLegalContents
